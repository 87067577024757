
$bg-darkgray: #323232;
$bg-white:#ffffff;
$bg-black: #000000;
$bg-lightgray:#dddddd;
$bg-red: #9D1B33;
$bg-green: #068513;


svg{
    &.text-red {
        path{
            color: #bf1914 !important;
        }
    }

}

ol.decimal {list-style-type: decimal;
li{
    line-height: 50px;
    padding: 0px 10px;
}
}

/* Mapping Preview starts */
.playerbox{
    padding: 0px !important;    
    box-sizing: border-box;
    border-radius: 5px !important;
    box-shadow: none !important;
    width: 99px;
    height: 127px;
    margin: 0px 5px 10px 0px;
    &.selected{        
        .top {
            background-color: $bg-green; 
        }
        .bottom{
            color: $bg-green;
            border: 1px solid $bg-green;
            border-radius: 0px 0px 5px 5px;
            .addplayer{                
                svg{
                    color: $bg-green !important;
                }
            }
        }
    }
    &.sold{        
        cursor: not-allowed;
        .top {
            background-color: $bg-red; 
        }
        .bottom{
            color: $bg-red;
            border: 1px solid $bg-red;
            cursor: no-drop;
            .addplayer{                
                svg{
                    color: $bg-red !important;
                    cursor: no-drop;
                }
            }
        }
    }
    &.teamname{
        border:none;
        .bottom{
            height: 127px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: $bg-black;
            letter-spacing: -0.5px;
            border-radius: 5px !important;
            .player-name{word-break: break-word;}
        }       
    }
    .top{
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        background-color: $bg-darkgray;
        border-radius: 5px 5px 0px 0px; 
        color:$bg-white;       
        height: 38px;
        font-style: normal;
        font-size: 14px;
        text-transform: uppercase;
    }
    .bottom{
        display: grid;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;  
        max-width: 380px;
        height: 89px;
        border-radius: 0px 0px 5px 5px !important;
        border: 1px solid $bg-lightgray;
        cursor: pointer;
        .addplayer{
            padding: 5px !important;
            svg{
                color: $bg-darkgray !important;
                font-size: 18px !important;
            }
        }
        .plus-icon{
            margin-top: 0px;
            width: 100%;}
         .terms-checkbox > span{   
                .select-text{
                    position: absolute;
                    background: rgb(255, 255, 255);
                    display: inline-flex;
                    left: 0px;
                    top: 7px;
                 }  
                 svg{
                     fill: transparent
                 }       
         } 
         
    }
    .select-large{
        height: 125px;
        width: 97px;
        position: absolute;
        top: -58px;
        left: -21px;
        span{
            height: 125px;
            width: 97px;
            top: 0px;
            left: 0px;
        }
        .MuiCheckbox-colorSecondary.Mui-checked:hover,
        .MuiCheckbox-colorSecondary.Mui-checked{
            background: transparent !important;
        }
        .MuiIconButton-colorSecondary{
            color:transparent !important;
        }
    }
}

/* Mapping Preview ends */

/* For Mobile View */
@media (max-width: 599.95px){   
    /*.playerbox{
        width: auto !important;
    }*/
}

/* For SM View */
@media(max-width: 768px){
   /* .playerbox{
        width: 25% !important;
    }*/
}