.global-tab{
  margin-bottom: 15px;min-height: auto !important;
  .MuiTab-root{
    min-height: 40px;
  }
  button:first-child{
    border-radius: 5px 0px 0px 5px;
  }
  button:last-child{
    border-radius: 0px 5px 5px 0px;
  }
  .MuiTab-textColorInherit.Mui-selected{    
    color: #fff;
    background-color: #cb3837;border: solid 1px #cb3837;}
    .MuiTab-textColorInherit{    
      color: #cb3837;
      background-color: #fff;opacity: 1;border: solid 1px #cb3837;}
}

.welcome-msg{
  display: flex;
  margin: 0px 16px;
}
.tile-card{
  box-shadow: none !important;
  border:1px solid rgba(0, 0, 0, 0.1);
  // background: #fff6f6 !important;
   // &:hover{
    // box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3) !important;
   // }
   .card-title {
    font-size: 1rem !important;
    color: #343434 !important;
    text-transform: uppercase;
    .MuiTypography-h5 {
      font-size: inherit;}
  } 
  }
  .MuiCardHeader-action{
    margin:0 !important;
  }
  .v-button{
    background:rgb(189,28,30) !important;    
    color:#fff !important;
    padding: 6px !important;
    .MuiSvgIcon-root{
      font-size: 18px !important;      
    }
  }
  td.center{text-align: center;}

  .tile-title{
    padding-bottom: 16px !important;
    .event-title{
      font-size: 15px !important;
      text-transform: capitalize;   
      margin-bottom: 0px;
      font-weight: 400;text-shadow: 1px 1px 1px #fff;
      .launch-icon{
        color: #bf1914 !important;
        font-size:16px;
        margin:0px 0px 2px 5px;
      }
      }
      .price{
        font-size: 18px !important;
        color: #bf1914 !important;
        line-height: 1.5;font-weight: 500;}
        .chip{
          .bg-success, .bg-danger, .warning-outline, .success-outline{
            height: 20px;
            color: #fff;
          }
          
        }
        .date, .sold{
          color: rgba(0, 0, 0, 0.54);
          margin: 0px;
          line-height: 26px;
        }
        
  }
    .tile-title h6{
    font-size: 14px ;
    color: #bf1914 !important;
    margin: 3px 0px 3px 0px;
    line-height: 20px;
    }
    .tile-title p{
    font-size: 12px !important;
    }

    .upsell{
      /*&:hover{
        ul{
          li{transform: translateX(4px);}
        }
      }
      ul{
        li{transition: transform .16s ease;}
      }*/
      table{
        tr{
          th:first-child, td:first-child{
            // width: 160px;
            text-align: left;
            white-space: nowrap;
          }
        }
        tr {
          th:last-child, td:last-child{
            width: auto;
            text-align: center;
            white-space: nowrap;padding-right:5px !important;
          }
        }
        tr{
          th{background-color: #f9f7f7 !important;}
          th, td{
            font-size: 12px !important;
          }
        }
      }
    }
    .upsell-list{
      border-radius: 5px;
      border: 1px solid rgba(0,0,0,0.12);
      li{
        margin-left: 8px;
      }
    }
      
   
    .upsell-modal{
      width: 600px !important;
    }
    .upcoming-events, .top-selling{
     .title span{
       font-size: 1rem;
     } 
     table{
       border:none;
       td {border-bottom: 0.5px dashed #e5e7e9 !important;vertical-align: top !important;}  
       tr:last-child td{border-bottom: 0px !important;}     
       th:last-child, td:last-child{ text-align: center;}
       th.center, td.center{text-align: center;}
       thead{
         th{background: #fff !important; font-size: 13px !important; color:#000;}
       }
     }
    }
    .top-selling{ 
          
      table{
        th:last-child, td:last-child{ text-align: center; width:auto;}
        th.center, td.center{text-align: center;}
      }
     }
   

.view-more{
  .pop-btn {
    position: fixed;
    top: 40%;
    right: -12px;
    border: 0px;
    border-radius: 5px 0px 0px 5px;
    padding: 3px 0px 3px 3px;
    background: #fff;
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3) !important;
    z-index: 999;
    transition: 0.5s linear all;
    &:hover{
      background: #fff;transition: 'all .5s linear';
    }
        .pop-btn-border {
          border: 1px dashed #efb1af;
          display: block;
          border-radius: 3px 0px 0px 3px;
          padding: 5px 7px 2px 5px;
          margin-right: -1px;
          color: #bf1914;
          text-align: right;
          font-size: 14px;
          // background: #bf1914;
          span {
            display: block;
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            max-width: 50px;
            text-transform: capitalize !important;
        }
      }
    }
}
.MuiListItem-button {
    font-size: 14px !important;
}
.img-responsive{
    max-width: 100%;
    max-height: 100%;
}
.map-wrapper{
height: 600px;
position: relative;
}
.select-box{
  height: 37px;
    padding-top: 8.5px;
    padding-bottom: 8.5px;
}
@media print {
  .jvectormap-zoomin, .jvectormap-zoomout {
    display:none !important;
  }
}