
main{
    .pageContent{
      width: 100%;
      padding: 0px 15px;
      margin-bottom: 50px;
      margin: 0px auto; 
    
    .page-title{
      display: flex;
      border: solid 1px rgba(224, 224, 224, 1);
      h6{
      border-bottom: 0px;
      margin-bottom: 0px !important;padding: 10px;font-size: 16px;
     }
    }
    .order-list{
      padding: 0.5rem 1rem !important;
      width: 50%;
      display: inline-block;
      span.text-gray{
        color: #a5a1a1 !important;
        font-size: 14px !important;
        margin-bottom: 0px;
      }
      button{
        span{color: inherit !important;}
      }
      h6{
        font-size: 14px !important;
        margin-bottom: 20px;
        font-weight: 500;
      }
    }
    
  }
  .btn-export{
    position: absolute;
    right: 10px;
    top: 0px;}
  
  }
  a{
    &.export-csv:hover{text-decoration: none; }
  }
  .custom-control{
    .MuiInput-underline:before, .MuiInput-underline.Mui-focused:after, svg{
      display: none !important;
    }
  }
  .custom-morevertical {
    background: url(../../assests/more_vert.png) no-repeat 3px 3px;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: solid 1px #bf1914;
    border-radius: 20px;
    padding: 2px !important;
    text-align: center;
    .MuiSelect-select.MuiSelect-select{padding-right:0px;}
} 
.radio-datetime{display: flex;
  float: left;
  margin: 50px 8px 12px 13px !important;
    padding-bottom: 22px;
  + .ed-date-time{
    margin-bottom: 20px;border-radius: 5px;
    .time{border-radius: 0px 0px 5px 5px; width:130px;}
  }
  }
.exchange-ticket {
  .date-container{
    margin-top: 0px !important;
    .css-4l7j15{border:solid 1px #ccc;min-width: auto !important;}
    .MuiBadge-anchorOriginTopRightCircular::before{border: 1px solid #39529a;
      border-radius: 20px;
      background-image: url("../../assests/tick_mark.png") !important;
      background-position: 15px 13px;
      content: "";
      color: #39529a;
      position: absolute;
      top: -17px;
      width: 14px;
      right: -21px;
      font-size: 10px;
      height: 14px;
      text-indent: 3px;
      margin: 20px !important;
      padding: 0px;
      vertical-align: middle;
      line-height: 12px;}
  }
  .MuiIconButton-colorSecondary{color: #bf1914 !important;}   
}
/* custom radio css starts here */
label.exchangetime{margin-bottom: 0px;cursor: pointer;}
label.exchangetime > input[type="radio"] {
  display: none;
}
label.exchangetime > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #ff8282;top: -9px;
  position: relative;
}
label.exchangetime > input[type="radio"]:checked + * {
  color: #fff;
}
label.exchangetime > input[type="radio"]:checked + *::before {
  background: radial-gradient(#fff 0%, #fff 40%, transparent 50%, transparent);
  border-color: #fff;
}

label.exchangetime > input[type="radio"] + * {
  display: inline-block;
  padding: 0px 1rem;
}
/* custom radio css ends here */

.empty-outer{width: fit-content;
  margin: 5% auto !important;
  background: #f6f6f6;
  border: solid 1px #f1eeee; border-radius: 5px;}
  .empty-outer li{padding-top: 20px;padding-bottom: 20px;}
 .cart-empty{width: auto !important;
  height: auto !important;
  margin: auto;
  border-radius: 0px !important;padding-right: 10px;}
  .cart-empty img{width: auto;}

  /* For only firefox starts */
@-moz-document url-prefix() {  
  .exchange-ticket {
    .date-container .MuiBadge-anchorOriginTopRightCircular::before{
    background-image: url("../../assests/tick_mark.png") !important;
    background-position: 15px 13px;
     content: "";
  }
}
}
/* For only firefox ends */