.backbutton{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.heatmap{
    position: absolute;
    right: 0;
    .heatmapWrapper{
        display: flex;
        .value{
            width: 25px;
            height: 25px;
            margin: 0 5px;
            text-align: center;
        }
        .f-12{
            font-size: 10px;
        }
    }
}