article, aside, figure, footer, header, hgroup, 
  menu, nav, section { display: block; }
.disabled{
  opacity: 0.7;
    pointer-events: none;
    &.preview{
      margin: 0 auto;
      border-collapse: separate;
      border-spacing: 0;
      width:100%;
      background: #ffffff;
     
    }
    .bg-gray{
      background: #f6f6f6;
      border: solid 1px #f1eeee;
      border-radius: 5px;
      padding: 10px 20px;}
    .rdw-inline-wrapper, .rdw-list-wrapper, .rdw-text-align-wrapper, .rdw-image-wrapper{display: none;}
    .preview-footer { display: flex !important;
      color:#ffffff;font-size:12px;
      background: url("../../assests/footerbg.png") #000000 no-repeat top right;
      height: 40px;
    .text{
      line-height: 40px;
      img{
        padding: 0px 50px 0px 20px;height: 35px;
      }      
    }
   
    }
    .container-fluid{width:95%;padding: 30px 0px 0px 0px;}
    .addLogo{
      margin: 0px;border: none !important;
      background: transparent !important;
      display: contents;
    }
    .adds-outer, .qr-outer{width: 100% !important;}
    .header-bar {
      background: #bf1914;
      width: 100%; 
      height: 80px;
  }
  .header-left {
    flex-grow: 1;
    padding: 0px; 
    height: 30px; 
    margin: 0px; 
    padding: 0 0 0 10px; 
    border-top: 80px solid #ffffff; 
    line-height: 37px; 
    border-right: 40px solid transparent;        
  }
  .header-right{
    width: 35%;
  }
  .adds-outer{
    .bottom {
      .fist-half, .second-half{
        border: dotted 2px #d5d2d2;
      }
    }
  }
}
.preview-footer { display: none !important;}
.lowercase{
	text-transform: lowercase;
	}
.addLogo{
  padding: 10px !important;
  background: #f6f6f6;
  border: solid 1px #f1eeee; border-radius: 5px; 
  li{padding: 0px !important;
    .text{padding: 10px !important; margin: 0px !important;}
    button{margin: 0px !important;}
  }
  .logo{width: 105px !important;
    height: 70px !important;
    margin: auto;
    border-radius: 0px !important;
    img{width: auto;object-fit: fill;}
  }
}
.addLogo-right{
  width: auto;
  float: left;
  margin: 1% 0px !important;padding: 10px;
  .note{color: #323232;
    font-size: 11px;}
}
button.close{
  top: 0px;
    position: absolute;
    z-index: 999;
    right: 0px;
    border: solid 1px #fff;
    color: #bf1914;
    width: 32px;
    height: 33px;
    background: #fff;
    border-radius: 0px;cursor: pointer !important;
}

.qr-outer{width: 70% !important;
  margin: 2% 0px !important;
  background: #f6f6f6;
  border: solid 1px #f1eeee; border-radius: 5px;
  li{padding-top: 20px;padding-bottom: 20px;
    .qrcode{width: auto !important;
      height: auto !important;
      margin: auto;
      border-radius: 0px !important;padding-right: 40px;
      img{width: auto;}
    }
    }
}

.adds-outer{width:70% !important;
  margin: 1% 0px !important;
  background: #f6f6f6;
  border-radius: 5px;
  padding: 30px;border: solid 1px #f1eeee;
  .adds-inside{
     width: 680px !important;
    height: 230px;
    border: dotted 2px #d5d2d2; 
    margin: 5px auto !important;
    background: #fff;
    img{
      display: flex;
    }
    p{display: inline-flex;
      margin: 0px;
      font-size: 12px;}
  }
  .bottom{
    width: 680px !important;
    height: 280px;
    margin: 5px auto !important;
    background: #fff; 
    border: none; 
    .fist-half, .second-half{
      // width: 236px !important;
      border: dotted 2px #d5d2d2; 
      margin: 5px auto !important;
      background: #fff;
      float: left;
      width: 49%;
      height: 280px;
      cursor: pointer;
      img{
        display: flex;
        border: none;
        width: 100%;
        height: 280px;
        object-fit: contain;
        margin: 30px 0px !important;
        padding: 0px !important;
      }
      p{display: inline-flex;
        margin: 0px;
        font-size: 12px;}
    .preview{
      button{
        margin: 109px 28%;
      }
    }
      
    }
    .second-half{      
      float: right;      
    }
  }
}
.inputfile {
  position: relative;
  display: inline;
  input {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width:100%;
    height:100%;
  }
  span{
    color: #bf1914;
    padding: 0px 5px;
    cursor: pointer;
  }
}
.icons{
  .MuiBox-root{ margin: 5px 0px;
    .MuiBottomNavigation-root{height: auto;}
    button{border: solid 1px #EDEBF9;
      background: #ffffff;
      border-radius: 3px;
      margin: 0px 5px;
      height: 54px;}
  }
  .MuiBottomNavigationAction-root{min-width: 60px !important;}
  .twitter, .facebook, .instagram, .linkedin, .youtube{
    width: 35px;
    height: 35px;
  }
  .twitter{color:#039DED; }
  .facebook{color:#39529A;}
  .instagram{color:#F68845;}
  .linkedin{color:#0077B7;}
  .youtube{color:#FF0000;}
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.first-ads{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 230px;
  position: relative;
  input{opacity: 0;
    position: absolute;
    z-index: 1;height: 100px;}
  label{
    span{
      color: #bf1914;
    padding: 0px 5px;
    cursor: pointer;
    }
  }
  p{
    margin-bottom: 0.5rem !important;
  }
  .preview {
    display: flex;
    flex-direction: column;
    height: 280px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    img{
      max-width: 100%;
      max-height: 230px; 
      margin: 24px 0px -2px 0px;
      
      &:hover{
        background:#ccc;
      }   
    }    
    &:hover{
      .delete{
        cursor: pointer;
        padding: 104px;
        background: rgba(0,0,0,0.2);
        color: white;
        border: none;
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 9;
      }
    }
    button{
      position: absolute;
    width: auto;
    text-align: center;
    z-index: 9;
    margin: 99px 39%;
    }
    .delete{position: absolute;
      width: 100%;
      text-align: center;
    z-index: -1;}
  }
  
}
/*  Ticket Confirmation starts */
.main-content {
  /*border: 1px solid #bf1914;*/
background:#f8f8fa;
}
.header {
height:80px;
display: flex;
  padding: .5rem;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #000000 0%, #000000 70%, #000000 70%, #bf1914 70%, #bf1914 100%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #000000 0%, #000000 70%, #000000 70%, #bf1914 70%, #bf1914 100%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 70%, #ffffff 70%, #bf1914 70%, #bf1914 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#bf1914', GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
}
.header img{
height: 70px;
  width: auto;
  object-fit: cover;
}
.header .event-title{display: flex;
padding: 25px 15px;
  line-height: 20px;
  height: 20px;
color:#323232;font-weight: 500;float:left;font-size: 24px;
}

.booking-footer {
  background: #000000;
  padding: .5rem;
  font-size: 12px;
  color: #fff;
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #000000 0%, #000000 40%, #000000 40%, #bf1914 40%, #bf1914 100%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #000000 0%, #000000 40%, #000000 40%, #bf1914 40%, #bf1914 100%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #000000 0%, #000000 40%, #000000 40%, #bf1914 40%, #bf1914 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#bf1914', GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
}
.footer img {
  height: 30px;
  vertical-align: middle;
  margin: 0 5px;
}
.qr-code{ 
  width: 250px;
}  
.mail-content tr td:first-child{
  font-size: 14px;
  vertical-align: top;
  width: 180px;
  color:#14142B;
}
.mail-content tr td.hr-border{
   border: none;
       border-bottom-color: #c7c3c3;
       border-bottom-style: dashed;
       border-bottom-width: 2px;
   padding: 10px 0px;
}
.mail-content tr td p{
margin-bottom: 0px;
  margin-top: 5px;text-align: left;}
a{text-decoration: none;}
.download{background-color: #fff;
  border: 1px dashed #c3c3c3;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px 0px 20px;
  user-select: none;
  transition: all .1s ease;
  justify-content: center;
  align-items: center;
  width:200px;display: flex;}
.download p{margin:0px 0px 5px 0px;width: 200px;
  word-break: break-all;
  white-space: break-spaces;}
.pt-0{padding-top:0px;}
.pt-3{padding-top:48px;}
/*  Ticket Confirmation ends */
.rdw-editor-wrapper{
  border: 1px solid rgba(0,0,0,.125) !important;
}

@media(max-width: 768px){
  
  .qr-outer{
    width: auto !important;
  }
  .adds-outer{
    width: auto !important;
    .adds-inside{
      width: auto !important;
    }
    .bottom{
      width: auto !important;
      height: auto !important;
    }

  }

  .col-1-5{
    width: 33% !important;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    color:#878787;
    text-transform: uppercase;
    p{color:#000000;
      font-weight: 400;
      text-transform: none !important;font-weight: 600;}
  }
  .disabled{
  .preview-footer{
    background-position: 200px 0px;
    .text {
      img {
        padding: 0px !important;
    }
    }
    
  }
}
}
@media(min-width: 767px){
  .col-1-5{
    width: 20%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    color:#878787;
    text-transform: uppercase;
    p{color:#000000;
      font-weight: 600;
      text-transform: none !important;}
  }
  
}

/* For Mobile View starts*/
@media (max-width: 599.95px){  
  .qr-outer{
    width: auto !important;
  }
  .adds-outer{
    width: auto !important;
    .adds-inside{
      width: auto !important;
    }
    .bottom{
      width: auto !important;
      .fist-half, .second-half{
      width: 100%;
      height: auto;
      }
    }

  }
  .col-1-5{
    width: 50% !important;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
/* For Mobile View ends*/