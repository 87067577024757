

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#root{background: #fff;}
main{
  min-height: calc(100vh - 109px);margin-bottom: 0px;
}

/* For smaller scroll starts */
::-webkit-scrollbar {
  width: 6px; height: 6px;
}
/* Track */ ::-webkit-scrollbar-track {
  background: #F0F0F0;
}
/* Handle */ ::-webkit-scrollbar-thumb {
  background: #CDCDCD;
}
/* Handle on hover */ ::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}
/* For smaller scroll ends */

/* Loading Starts */
.svgLoader {
  animation: spin 3.5s linear infinite;
  margin: auto;
}
.loader-container{width: 100%;}
.Loader {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  justify-content: center; 
  color: #bf1914;
}

.Loader #c1, .Loader #c2 {
        animation: 
          size cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s, 
          opacity cubic-bezier(0.3, 0.61, 0.355, 1) 1.8s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        stroke-opacity: 1;
      }
      @keyframes size{
        0% { transform: scale(0); }
        100% { transform: scale(1); }
      }
      @keyframes opacity {
        0% { stroke-opacity: 1; }
        100% { stroke-opacity: 0; }
      }
      .Loader #c2 { animation-delay: -.9s; }
/*.divLoader {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.divLoader svg path{fill:#bf1914 !important;}*/
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Loading Ends */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS Starts */

body {   
  background: #f6f7fb;
  letter-spacing: 0px;
  font-size:14px;
  font-family: 'Roboto', sans-serif;
}
html, body, #root{
  height: 100%;
}

@font-face {
  font-family: 'OstrichSans-Medium';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/OstrichSans-Medium.eot') format('eot'), /*IE9 Compat Modes */
       url('fonts/OstrichSans-Medium.otf') format('otf'), /* IE6-IE8 */
       url('fonts/OstrichSans-Medium.svg') format('svg'), 
       url('fonts/OstrichSans-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/OstrichSans-Medium.woff2')  format('woff2'), /* Super Modern Browsers */
       url('fonts/OstrichSans-Medium.ttf')  format('ttf') /* Safari, Android, iOS */
}
button, button:focus{
outline: none;  
}

/* Login CSS Starts */
.login-bg{
  max-width: 520px;
  margin: 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
}
.loginLogo{
height: 121px !important;
width: 212px !important;  
margin-top: 20px;
position: absolute !important;
}
.loginLogo img {
  z-index: 999;
  margin-left: -2px;
}
.loginTop{
height: 101px;
width: 100%; 
background-color: #bf1914;
border-radius: 20px 20px 0px 0px; 
position: relative;
bottom: -20px;
}
button span{
  text-transform: capitalize !important;
}
.btn-redoutline {
border: solid 1px #bf1914 !important; 
 color: #c4201b !important;
 border-radius: 6px; 
outline: 0px !important;
font-weight: 400 !important; 
text-transform: capitalize !important;
background: #fff !important;
box-shadow: none !important;
}

.link-gray{
  color:rgba(0, 0, 0, 0.87) !important;
  padding: 6px 20px !important;
  display: block;
  background: #e0e0e0 !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  /* font-size: 16px !important; */
  border:none !important;
  font-weight: normal !important;
}
.link-gray:hover{
  background: #d5d5d5 !important;
  text-decoration: none !important;
}
.link-red{
  color:rgb(255,255,255) !important;
  padding: 6px 20px !important;
  display: block;
  background: rgb(189,28,30) !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  /* font-size: 16px !important; */
  border:none !important;
  font-weight: normal !important;
}
.link-red:disabled{
  color: rgba(0, 0, 0, 0.26) !important;

  background-color: rgba(0, 0, 0, 0.12) !important;
}
.link-red:hover{
  color:rgb(255,255,255) !important;
  background: rgb(207,30,33) !important;
  text-decoration: none !important;
}

.btn-sm{
  padding:.5rem 1rem !important;
  font-size: 12px !important;
}
h5, .title{  
 /* .MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-root,  */
font-family: 'OstrichSans-Medium', sans-serif !important;
color:#343434 !important;
font-size: 20px !important;
letter-spacing: 2px !important;
text-transform: uppercase;
font-weight: 600 !important;

}
.breadcrumbs{
  margin: 0px !important;
  padding: 12px 16px !important;
  display: flex !important;
}
.pb-0{ padding-bottom:0px !important;}
.MuiDialog-paper button svg.closeIcon {
color:#ffffff !important;
}
.MuiMenu-paper ul li{font-size: 14px !important;}
/*.link{
text-transform: capitalize;
font-weight: normal;
text-decoration: underline;
font-size: 14px;  
}*/

/* Header CSS Starts */
nav li:last-child p{
color:#969494 !important;
}
/* header{
box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 7%), 0px 4px 5px 0px rgb(0 0 0 / 8%), 0px 1px 10px 0px rgb(0 0 0 / 5%) !important;
background: #bf1914 !important;
color:#646567 !important;

} */
.flex-end{
display: flex;
justify-content: flex-end;
}
/* SideBar CSS Starts */

.logoHeader{
height:38px !important;
width: 88px !important; 
border-radius: 0px !important;
position: absolute !important;
top: 8px;
}
.logoHeaderBg{
background: #000000;
padding: 32px 10px 32px 20px;
}
.toolbar{
background: #0d0d0d;
height: 100%;
color: #b8b8b8;
}
.toolbar svg{
color: #b8b8b8;
}
.list-item span{
font-size: 16px !important;
}
.submenu .list-item span{
  font-size: 14px !important;
  }
  .submenu .MuiListItem-root{padding-top:3px; padding-bottom: 3px;}
/*.active svg{
color: #c1211c;
}*/
.active .active-bdr{
border-left: solid 5px #c1211c;transition: transform 0.6s linear;
transform: scale(0.97, 0.93);
}
.text-red{
color: #bf1914 !important;
}
.icon-minwidth{
  margin-right: 10px;
  transition: 'all .5s linear';
} 
.active-bdr{ border-left: solid 5px #c1211c !important;transition: transform 0.6s linear;
  transform: scale(0.97, 0.93);animation: animateleft 0.9s;}
.inactive{border-left: solid 5px #0d0d0d !important;transition: transform 0.6s linear;
  transform: scale(0.97, 0.93);animation: animateleft 0.9s;}
.active-bdr .list-item, .inactive .list-item {padding-left: 0px;}
.active-bdr.MuiListItem-button{background-color: rgb(255 255 255 / 7%) !important;}
/* Style for User Management */

.outlined-primary {
color: #3f51b5 !important;
border: 1px solid rgba(63, 81, 181, 0.5)!important;
}
table.MuiTable-root:not(.e-dp-viewdays) {
margin-top: 0px !important;
border: solid 1px rgba(224, 224, 224, 1);
}
table.MuiTable-root thead th{
color: #666 !important;
background-color: #e8e8e8 !important;
padding: 0.30rem 0.25rem!important;
}
table.MuiTable-root tbody td:not(.current-month) {padding: 0.30rem 0.8rem 0.30rem 0.25rem !important;white-space: nowrap;}
table.MuiTable-root tbody td:last-child button {padding: 5px !important;}
table.MuiTable-root tbody td:last-child button svg{width: 20px !important;
  height: 20px !important;}
table tbody td .company-name{
border-radius: 3px;
  border: solid 1px #ccc;
  width: max-content;
  padding: 2px 6px;
  display: inline-block;
  margin: 0px 2px;
}
table.MuiTable-root td:not(.current-month), table th:not(.current-month):not(.week-end) {
vertical-align: middle !important;
}
table.MuiTable-root tr th:last-child:not(.current-month), table tr td:last-child:not(.current-month) {
width: 150px;
  text-align: center;
  white-space: nowrap;
  padding-right:5px !important;
}
.smaller-input{
width: 100% !important;
}
.create-user .MuiFormControl-root{
width: 100% !important;
font-size: 14px;
}
.smaller-input label  {
font-size: 14px;
padding: 2px 5px;
}
.fSize-12{
font-size: 12px;
}

.MuiButtonGroup-vertical button{
border: none !important;
}
.MuiButtonGroup-vertical span{
justify-content: left !important;
}
select:disabled svg{
display: none !important;
}
  .event-list li{
    padding: 5px 15px;
  }

.bg-profile{
background: #fae2e2;
}
.bg-profile img{
  border-radius: 200px;
}
footer .bottom-link{
font-size: 12px;
line-height: 45px;
}
footer{
width: 100%; position: relative; bottom: 0px;
}
footer h6{
font-size: 14px !important;
}
footer p{
font-size: 12px !important;
}
footer .MuiButtonGroup-grouped, footer .MuiBottomNavigationAction-label{
font-size: 12px !important;
  padding: 0px;
}
.logoFooter{
width: auto !important;
height: auto !important;
border-radius: 0px !important;
margin:0 5px !important;
}
.logoFooter img{
width: 70px !important; 
border-radius: 0px !important;
position: relative !important;
display: inline-block !important;
}
.footer-bottom{
display: flex;
background: #000;
color: #fff;
justify-content: center;
align-items: center;
padding: 5px;
}
footer .MuiPaper-outlined{
border:none;
border-radius: 0;
}
.MuiTablePagination-spacer{flex: inherit !important;}
.MuiTablePagination-root{display: block !important;}
.MuiTablePagination-selectRoot{
margin-right: auto !important;
}
/*.MuiMenu-paper{
margin-top: 49px !important;
}*/
.MuiDrawer-paper{
border: none !important;
}
.MuiTablePagination-root .MuiToolbar-gutters{
padding: 0px 5px !important;
}
.MuiList-padding{
padding: 0px !important;
}
.MuiTab-root.Mui-selected{
  background: #f3dddc;
}
.MuiTab-root h6{font-size: 16px !important;}
.truncate {white-space: nowrap;
max-width: 200px;
overflow: hidden;
text-overflow:ellipsis;}
.truncate[data-title] {
font-size: 18px;
position: relative;
cursor: help;
}
.truncate[data-title]:hover::before {
content: attr(data-title);
position: absolute;
bottom: -46px;
padding: 10px;
background: #000;
color: #fff;
font-size: 14px;
white-space: nowrap;
}
.form-control{
width:100% !important;
}
.choosefile{
border: solid 1px #ccc;
  padding: 13px 20px;
  border-radius: 4px;
  width: 100%; cursor: pointer;
}
.choosefile input{font-size: 14px; }
.upload-label{
position: absolute;
  float: left; 
  left: 23px;
  top: 0px;
  background: #fff;
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px 7px;
}

.list-item:hover{
text-decoration: none;
  /* background-color: rgb(220 170 170 / 4%); */
}
.MuiListItem-button:hover{background-color: rgb(220 170 170 / 4%) !important;transition: 'all .5s linear';}
.event-card{
position: relative;
box-shadow: none !important;
border:1px solid rgba(0, 0, 0, 0.1);
}
.event-card:hover{
box-shadow: 0 5px 18px rgba(0, 0, 0, 0.3) !important;
}
.event-card .event-action .link-red{padding: 1rem 1.5rem!important;font-size: 16px!important;}
.event-bg{
background: rgba(0,0,0,.035);
}
.event-title h4{
font-size: 16px !important;
text-transform: capitalize;   
margin-bottom: 0px;
}
.event-title h6{
font-size: 14px !important;
color: #bf1914 !important;
margin: 8px 0px 0px 0px;
line-height: 1;
}
.event-title p{
font-size: 12px !important;
}
.event-action{
display: none !important;
transition: all 5s linear;
}
.event-card:hover .event-action{
position: absolute;
top: 0;
border: 0;
left: 0;
right: 0;
margin: auto;
display: flex !important;
justify-content: center;
align-items: center;
height: calc(100% - 81px);
z-index: 10;
background: rgba(0, 0, 0, 0.3);
transition: all 5s linear;
animation: fadein .5s ease-in;
}

@keyframes fadein {
from { opacity: 0.7; }
to { opacity: 1; }
}

.event-action a:hover{
text-decoration: none;
}
.event-details h2{
font-size: 24x !important;
}
.event-details h3{
font-size: 24px;
}
.event-details h4{
font-size: 22px !important;
  color: #646161 !important;
  text-transform: uppercase; margin-bottom: 0px;
}
.event-details img:hover{
transition: none !important;
}
.no-event{
width: 400px !important;
height: 300px !important;
margin:auto;
}
.details-img{
  max-width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  border: 0px solid #fff;
  padding: 0px;
  background-color: transparent;
}
.details-img img{
width: 100%;
  height: 100%;
  object-fit: cover;
transition: none !important;
}
.details-img img:hover{
transform: none !important;
}
.event-info{
box-shadow: none !important;
border: 1px solid rgba(0, 0, 0, 0.1);
}
.event-dates{
box-shadow: none !important;
border: 1px solid rgba(0, 0, 0, 0.1);
margin-bottom:1rem;
}
.event-content{
padding: 0 !important;
}
.dates-content{
padding: 0 !important;
display: flex;  
}
.ed-date-time{
  background: #bf1914;
  color: #fff;
  display: flex;  
  width: 130px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px 0px 0px 5px;
  }
  .ed-date-time .date{
  text-align: center;
  margin-bottom: 0;  
  padding:.5rem;
  line-height: 4;
  font-size: 12px;
  }
  .ed-date-time .date{
  display: block;
  font-size: 14px;
  }
  .ed-date-time .time{
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;  
  padding: .15rem .5rem;
  background: rgba(0,0,0,0.2);
  line-height: 35px;
  text-transform: uppercase;border-radius: 0px 0px 0px 5px;
  }
.ticket-details{
width: calc(100% - 100px);
margin:0 !important;
}
.ticket-details h5{
    font-size: 14px !important;
}
.select-box{
font-size: 14px;
border-radius: 3px;
margin-top: 5px;
padding: 3px 7px;
border:1px solid rgba(0,0,0,0.3);
background: #fff;
}
.btn-action {
color: rgb(255,255,255) !important;
padding: .5rem;
display: inline-block;
background: rgb(189,28,30) !important;
text-decoration: none;
text-transform: uppercase;
border-radius: 6px;
font-size: 16px;
border: none;
margin: .25rem !important;
}
.btn-action:hover{
color:rgb(255,255,255) !important;
background: rgb(207,30,33) !important;
text-decoration: none !important;
}
.btn-action.disabled{
  background: rgb(0,0,0,0.2) !important;color: #999 !important;border: solid 1px #c8c8c8 !important;
}
.btn-action.disabled:hover{
  background: rgb(0,0,0,0.2) !important; 
}
.btn-action.bg-orange{
  background: #ffa500 !important;border: solid 1px #ffa500 !important;
}
.btn-action.orange:hover{
  background: rgb(0,0,0,0.2) !important;
}
.btn-action.bg-green{
  background: #28a745!important;
}
.btn-action.bg-green:hover{
  background: rgb(55 187 85) !important;
}
.ed-title{
background: rgb(189,28,30);
position: absolute;
bottom: 0;
padding: .5rem 1rem;
}
.ed-title h4{
color: #fff !important;
font-size: 24px !important;
margin-bottom: 0px;
}
.ed-title h6{
color:#fff !important;
font-size: 16px !important;
margin-bottom: 0;
font-weight: 400;
}
.ed-para{
padding: .5rem 1rem;    
font-size: 14px !important;
}
.event-tab .MuiTabs-flexContainer{
justify-content: center;
margin: 2rem 1rem;
}
.event-tab .btn{
background: #fff;
border:1px solid #000;
margin:0 .5rem;
}
.event-tab .MuiTab-root.Mui-selected{
background: rgb(189,28,30);
border:1px solid rgb(189,28,30);
color:#fff;
}
.event-tab .MuiTabs-indicator{
display: none;
}
.listing-badge.now-open {
background-color: #54ba1d;
}
.listing-badge {
background-color: #333;
float: left;
position: absolute;
transform: rotate(
45deg);
right: -75px;
top: -4px;
text-align: center;
width: 162px;
font-size: 10.5px;
margin: 0px 5px;
z-index: 999;
color: #fff;
font-weight: 500;
line-height: 30px;
padding: 0px;
}
.notification .MuiMenu-paper{
top:50px !important;
}

header{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+70,000000+70,bf1914+70,bf1914+100 */
background: #000000; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #000000 0%, #000000 70%, #000000 70%, #bf1914 70%, #bf1914 100%) !important; /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #000000 0%,#000000 70%,#000000 70%,#bf1914 70%,#bf1914 100%) !important; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #000000 0%,#000000 70%,#000000 70%,#bf1914 70%,#bf1914 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#bf1914',GradientType=1 ) !important; /* IE6-9 fallback on horizontal gradient */
box-shadow: none !important;
}
.searchContainer{
float: left;
  border: solid 1px #7e7c7c;
  padding: 3px;
  margin: 8px;
  margin-left: 50px;
  font-size: 12px !important;
  background: #e7e7e7;
}
.searchContainer .MuiInput-root{font-size: 12px !important;}

.searchContainer label{
font-size: 14px !important;
}

.article-container {
border: 1px solid #000000;
overflow: hidden;
position: relative;
}

.article-img-holder {
width: 100%;
height: 100%;  
background-position: center;
background-size: cover;
background-repeat: no-repeat;
transition: all 1s;
}

.article-img-holder:hover {
transform: scale(1.2);
}
.dropzone p{
font-size: 12px; margin: 10px;
}
.dropzone svg{
width: 26px; height: 26px;
}
.dropzone .MuiDropzoneArea-root{
max-height: 100px;
min-height: 70px;
}
.dropzone .MuiDropzoneArea-root .MuiGrid-item{padding: initial;}
.breadcrumbs-level2{
margin: 0px 8px 8px 16px !important;
}
.breadcrumbs-level2 li, .breadcrumbs-level2 p, .create-user label{
font-size: 14px;
}
.breadcrumbs-level2 li a{
color: #bf1914 !important;
}
table td button svg{
width: 18px;
height: 18px;
}
.control-sm [role="button"]{
padding: 10px 10px;
  font-size: 14px;
}
.cart-align{ 
/* position: absolute;
right: 0px;
top: 10px;
color: #FFE; */
height: 100%;
align-items: center;
}

.icon-medium{height: 24; width: 24; display:inherit;}
.event-title{
font-size: 1rem;
line-height: 1.25;}

.success-outline{
  background-color: #4caf50 !important;
  border: 1px solid rgba(46, 125, 50, 0.7) !important;
  color: #fff !important;
  font-size: 12px !important;
}
.warning-outline{
  background-color: transparent;
  border: 1px solid rgba(211, 47, 47, 0.7) !important;
  color: rgb(211, 47, 47) !important;
  font-size: 12px !important;
}

/*.upsell table tr th:first-child, .upsell table tr td:first-child{
  width: 160px;
    text-align: left;
    white-space: nowrap;
}
.upsell table tr th:last-child, .upsell table tr td:last-child{
  width: auto;
    text-align: center;
    white-space: nowrap;padding-right:5px !important;
}*/

.MuiOutlinedInput-input{font-size: 14px !important;}
.MuiAlert-standardWarning{
  border: solid 1px #efd8b8 !important;
  padding: 0px 5px !important;
}
.MuiTypography-subtitle1{
  line-height: 18px !important;
}
.MuiSwitch-colorSecondary.Mui-checked{
  color: #cb3837 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: #cb3837 !important;
}
.upsell-modal .leftimage{      
  width:100px;
  height: 100px !important;
}
.upsell-modal h5{font-size: 16px !important;
}
.scrollbar {
  height: 200px;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
  border-radius: 0px;
  }

  .scrollbar-taller {
    height: 300px;
    } 
  .scrollbar::-webkit-scrollbar {
  width: 6px;height: 6px;
  background-color: #F5F5F5; }
  .scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;

  background-color: #d1cccc }
  .scrollbar {
  scrollbar-color: #d1cccc #F5F5F5;
  }
  select{
    outline: none;
  }
  select option{
    color:#000;
  }
    .booking-bg{
      width: 50%;
      margin: 0px auto;
    }
    .booking-bg img{
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      object-fit: cover;
      width: 150px;
    }
    .booking-bg p{
      margin: 2px 0px 8px 0px;
    }
    .promo-image{
      width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    border: 0px solid #fff;
    padding: 0px;
    background-color: transparent;
    }
    .qr-detailsbg {
      width: 20%;
      margin: 10px auto;
    }
    .qr-detailsbg .reader{
      width:180px; height: 180px;
    }
    .qr-detailsbg section div{
      border:0px !important;
    }
    .qr-content{
      padding: 0 !important;
      display: flex;  
      }
    .qr-details{
      width: calc(100% - 100px);
      margin:0 !important;
      }
     
      .qr-content .ticket-details h5{
        font-size: 16px !important;
        margin-top: 10px;
      }
      .qr-content .ticket-details h6{
        font-size: 14px !important;
        font-weight: 400 !important;
      }
      .qr-content .ticket-details .total-bg{
        background: #fbf8f8;
      }
      .qr-content .ticket-details strong{
        float: right;
      }
      .qr-content .ticket-details .padding-push{
        padding-left:50px;
      }
      .qr-content .ticket-details p{
        margin:0px;
      }
      .qr-content .ticket-details .total .cost{
        font-size: 16px;
        color: #cf1e21;
      }
      .is-disabled
      {
        pointer-events: none;
        opacity: 0.7;
      }
      button.close-preview{
        float: right;
        margin: 0px !important;
      }
      button.close-preview svg{
        color: #646464 !important;
      }
     .date-container{
      margin-top: 112px !important;
     }
     .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
      background-color: #000000 !important;
     }
     .MuiPickersStaticWrapper-staticWrapperRoot, .MuiPickersBasePicker-pickerView{
       min-width: auto !important;max-width: 100% !important;justify-content: flex-start !important;
     }
     .MuiPickersToolbarText-toolbarTxt{
      font-size: 20px !important;
     }
     .MuiPickersToolbar-toolbar{
      height: 50px !important;
     }
     .MuiFab-secondary {
      background-color: #bf1914 !important;
      }
 .upsell-tbl th.desc, .upsell-tbl td.desc{min-width: 100px;
  white-space: pre-wrap;
  max-width: 300px;}
  .qr-tbl td button.link-gray{
    padding:2px 10px !important;
  }
  .custom-checkbox label{margin-bottom: 0px;}
  .custom-checkbox label span{font-size: 14px;}
  td.apikey{min-width: 300px !important;
    word-break: break-all;white-space: pre-wrap;
    line-height: 18px;}
    td.apikey p{background: #fff1f1;
      margin: 0px;
      border-radius: 5px;
      padding: 6px;
      border: solid 1px #f3dede;}
      td.apikey p:hover{background: #fff;}
    fieldset {
      min-width: 0;
      padding: 10px;
      margin: 0px;
      border: solid 1px #ccc;
  }
  legend{width: auto;}
  legend label{margin: 0px 7px;}
  .ots-fees button{padding: 7px 8px;
    margin: 12px 0px;
    height: 30px;}

.ots-fees button.Mui-selected{
  color: #fff;
  background-color: rgb(237 77 73);}
.ots-fees button.Mui-selected:hover{background-color: rgb(237 77 73);}
.small-icon svg{width: 20px !important;height: 20px !important;}
.MuiTableCell-root.MuiTableCell-body button.MuiIconButton-root .MuiSvgIcon-colorSecondary {color: #bf1914 !important;}
.MuiCheckbox-colorSecondary.Mui-checked{color: #bf1914 !important;}
.MuiCheckbox-root, .MuiRadio-root{color:#bf1914 !important;}
.MuiFormControlLabel-root .MuiFormControlLabel-label{font-size: 14px !important;}
tr{
  cursor: inherit !important;
}
table thead th{white-space: nowrap;}
.MuiDialog-paperScrollPaper button.MuiButtonBase-root.Mui-disabled{background: #c58181 !important;}
.date-container .MuiBadge-anchorOriginTopRightCircular::before,
  .exchange-ticket .date-container .MuiBadge-anchorOriginTopRightCircular::before {
      background-image: url("assests/tick_mark.png") !important;
      background-position: 15px 13px;
      content: "";  
  }    
  .dialogHead button{ padding: 8px;}
  .pointer {cursor: pointer; text-decoration:none;}
  .pointer:hover {cursor: pointer; text-decoration:underline !important;}
  .searchbar {float:right; padding:0px !important;}
  .selectbar {float: right;padding: 10px 16px !important; min-height:auto !important;height: 70px;}
  .selectbar.singleline{height: auto;}
  .selectbar .select-box{height: 37px;
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    width: 226px; 
    border-color: rgba(0, 0, 0, 0.23);}
  .promo-eventdate .date>label, .promo-eventdate .date input.MuiInputBase-input{ font-size: 13.5px !important;}
  .radio-option > .MuiFormGroup-root{flex-direction: initial;}
  .radio-option > .MuiFormGroup-root .example{line-height: 42px;font-size: 12px;color: rgba(0, 0, 0, 0.54);}
  
  

/*Back to top Starts */
    .back-to-top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      background: #bf1914;
      color: white;
      cursor: pointer;
      border-radius: 100px;
      border: none;
      box-shadow: 0 5px 10px #ccc;
      height: 40px;
      width: 40px;z-index: 9;
    }

    .back-to-top:hover {
      background: rgb(207,30,33) !important;
    } 
/*Back to top Ends */


/* The Modal for Widget Starts here  */
#myModal.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 99999; /* Sit on top */
	padding-top: 20px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  } 
  
  /* Modal Content */
  #myModal .modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px 20px 60px;
	border: 1px solid #888;
	width: 80%;border-radius: 10px;
  }
  #myModal .modal-content .close{text-align: right;}
  /* The Modal for Widget Ends here  */
  
    /* Ticket Allotment Starts here  */
    @media (min-width: 1280px){
.ticket-allotment .MuiGrid-grid-lg-6 {
        flex-grow: 0;
        max-width: 47.5%;
        flex-basis: 47.5%;
    }      
    

}
@media (min-width: 960px){
 
.ticket-allotment-threecontrol .MuiGrid-grid-sm-3 {
  flex-grow: 0;
  max-width: 32%;
  flex-basis: 32%; 
}
.ticket-allotment .MuiGrid-grid-sm-6 {
  flex-grow: 0;
  max-width: 47%;
  flex-basis: 47%;
}
.ticket-allotment .MuiGrid-grid-md-3 {
  flex-grow: 0;
  max-width: 49%;
  flex-basis: 49%;
}
}
@media(min-width: 600px){ 
  .ticket-allotment-threecontrol .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 31%;
    flex-basis: 31%;
}
.ticket-allotment .MuiGrid-grid-md-3 {
  flex-grow: 0;
  max-width: 47%;
  flex-basis: 47%;
}
.ticket-allotment .MuiGrid-grid-sm-6{
  flex-grow: 0;
  max-width: 47%;
  flex-basis: 47%;
}
}
    /* Ticket Allotment Ends here  */
	
/* For Mobile View */
      @media (max-width: 599.95px){  
        .MuiDialog-paperWidthMd{max-width: 90% !important;}   
        .ed-date-time {
          width: 130px;
        }   
        .ed-date-time .date{
          line-height: 8;
        }  

      .qr-detailsbg{width: auto;}
      .qr-content .ticket-details .padding-push{
        padding-left:0px;
      }
      .date-container{
        margin-top: 0px !important;
       }
       #myModal .modal-content{
          width: 98%;
          }
          .searchbar{width: 100%;}
          .searchbar .MuiFormControl-root{width: 90%;}
          .selectbar, .selectbar>div, .selectbar div>.select-box {width: 100% !important;}
    }     
 
    /* For SM View */
    @media(max-width: 768px){
      .MuiDialog-paperWidthMd{max-width: 90% !important;}
      .dates-content{
        display: inherit;transition: 'all .5s linear'
      }
      .ed-date-time .date{
        font-size: 12px;
      }
    }

/* Smooth scrolling IF user doesn't have a preference due to motion sensitivities */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}



/* For only firefox starts */
@-moz-document url-prefix() {
  h5, .title {
    font-family: "Helvetica", sans-serif !important;
    letter-spacing: 0px !important;
    font-weight: normal !important;
    font-size: 16px !important;
    padding: 4px 0px;
  }
  
  
}
/* For only firefox ends */

  /* For LG View */
  @media(min-width: 1120px) {
  .event-filter {
    min-width: 1000px
  }
}

.order-comments-cell {
  white-space: normal!important;
}