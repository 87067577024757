.welcome-msg{
  display: flex;
  margin: 0px 16px;
}
.tile-card{
  box-shadow: none !important;
  border:1px solid rgba(0, 0, 0, 0.1);
    &:hover{
    box-shadow: 0 0px 9px rgba(0, 0, 0, 0.2) !important;
    }
  }

  .tile-title{
    padding-bottom: 16px !important;
    .event-title{
      font-size: 15px !important;
      text-transform: capitalize;   
      margin-bottom: 0px;
      font-weight: 400;text-shadow: 1px 1px 1px #fff;
      .launch-icon{
        color: #bf1914 !important;
        font-size:16px;
        margin:0px 0px 2px 5px;
        &.disabled{
          pointer-events: none;
          opacity: 0.5;
        }
      }
      }
      .price{
        font-size: 18px !important;
        color: #bf1914 !important;
        line-height: 1.5;font-weight: 500;}
        .chip{
          .bg-success, .bg-danger, .warning-outline, .success-outline{
            height: 20px;
            color: #fff;
          }
          
        }
        .date, .sold{
          color: rgba(0, 0, 0, 0.54);
          margin: 0px;
          line-height: 26px;
        }
        .sold-ticket{
          color: #4caf50;
          font-weight: 500;
          background: rgba(76,175,80,0.1);
          padding: 2px 3px;
          border-radius: 3px;letter-spacing: -0.7px;
          line-height: 23px;
          height: 28px;
        }
  }
    .tile-title h6{
    font-size: 14px;
    color: #bf1914 !important;
    margin: 3px 0px 3px 0px;
    line-height: 20px;
    }
    .tile-title .upsell strong{
    font-size: 12px !important;
    }

    .upsell{
      /*&:hover{
        ul{
          li{transform: translateX(4px);}
        }
      }
      ul{
        li{transition: transform .16s ease;}
      }*/
      table{
        tr{
          th:first-child, td:first-child{
            // width: 160px;
            text-align: left;
            white-space: nowrap;
          }
        }
        tr {
          th:last-child, td:last-child{
            width: auto;
            text-align: center;
            white-space: nowrap;
          }
        }
        tr{
          th{background-color: #f9f7f7 !important;}
          th, td{
            font-size: 12px !important;
          }
        }
      }
    }
    .upsell-list{
      border-radius: 5px;
      border: 1px solid rgba(0,0,0,0.12);
      li{
        margin-left: 8px;
      }
      div{
        &.text-warning{ font-size: 12px;}
      }
    }
   
    .upsell-modal{
      width: 600px !important;
    }
    .upcoming-events, .top-selling{
     .title span{
       font-size: 1rem;
     } 
     table{
       border:none;
       td {border-bottom: 0.5px dashed #e5e7e9 !important;vertical-align: top !important;}  
       tr:last-child td{border-bottom: 0px !important;}     
       th:last-child, td:last-child{ text-align: center;}
       th.center, td.center{text-align: center;}
     }
    }
    .upcoming-events{
      canvas{
        pointer-events: none;
      }
    }
    .top-selling{           
      table{
        th:last-child, td:last-child{ text-align: center; width:auto;}
        th.center, td.center{text-align: center;}
      }
     }
   /* .upsell-modal .leftimage{
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      object-fit: cover;
      height: 200px !important;
    }*/


.switchToggle{
  .MuiSwitch-track{
    background: #f99d98;opacity: 1;   
  }
  .MuiSwitch-colorSecondary{
    .MuiSwitch-thumb{
      background: #f23b2f;
    }     
  .MuiSwitch-track{
    background: #f99d98;opacity: 0.4;    
  } 
}
.MuiSwitch-colorSecondary.Mui-checked{
  .MuiSwitch-thumb{
    background: #ccc;
  } 
}
.MuiSwitch-colorSecondary.Mui-checked +.MuiSwitch-track{
  background: #dbd6d6 !important;opacity: 1; 
} 
}
.linearBar{
  margin-top: 10px;
  h6{
    font-size: 20px !important;
  }
  
  span, [role="progressbar"] {
    background-color: #ccc;height: 20px;border-radius: 4px;
    span, .MuiLinearProgress-barColorPrimary {
      background-color: #bf1914;
    }
  }
  .tickets-lbl{text-align: right; line-height: 15px;font-weight: bold;color: #525252;
    span.value{min-width: 60px;
      display: inline-block;
      background: transparent;
      font-weight: normal;
      color: #999;
      max-width: 120px;}
  }
  .ticketsSold {
    height: 14px;
    width: 14px;
    background-color: #bf1914;
    border-radius: 50% !important;
    display: inline-block; margin: 5px 5px 5px 0px;
  }
  .remainingSold {
    height: 14px;
    width: 14px;
    background-color: #ccc;
    border-radius: 50% !important;
    display: inline-block; margin: 5px 5px 5px  0px;
  }
}
.v-button{
  background:rgb(189,28,30) !important;    
  color:#fff !important;
  padding: 6px !important;
  .MuiSvgIcon-root{
    font-size: 18px !important;      
  }
}
.btn-sm-group{
  button{
    font-size: 12px;
    text-transform: capitalize;
    height: 30px;border: 1px solid #cb504c;
    &.Mui-selected{color: #fff;
      background-color: #cb3837;}
      &.Mui-selected:hover{
        background-color:#d33530;
      }
  }
}
div{
  &.text-warning{ font-size: 12px;}
}
.buyer-details{ 
  .title{display: flex;
    padding: 16px;
    align-items: center;
  span{font-size: 1rem; }
  }
}
.tile-title{
  .item.flex{
    display: flex;
  }
} 
.infinite-scroll-component {
  overflow: hidden !important;
}

/* For Mobile View */
@media (max-width: 599.95px){   
.tile-title{
  padding: 10px !important;
}
.btn-redoutline{white-space: nowrap;}
}

/* For only firefox starts */
@-moz-document url-prefix() {
 
  .firefox {
    background: #ccc !important;
    display: block !important;
  }
  
}
/* For only firefox ends */